.contact-container .right {
  margin: auto;
  width: 60%;
  height: 80%;
  max-height: 600px;
  background: url("./maxi.jpg") no-repeat 0 0 / cover;
  display: flex;
  align-items: center;
}
.contact-container .right h1 {
  font-family: var(--prata-font);
  margin-top: 0;
  font-size: 40px;
  font-weight: 400;
  color: white;
  text-align: center;
  font-size: 55.2px;
  margin-left: -50px;
  margin-top: 50px;
  width: 100%;
}

.contact-container .left {
  margin: auto;
  position: relative;
  width: 40%;
  height: 80%;
  /*max-height: 800px;*/
  max-height: 550px;
}
.contact-container .left h1 {
  margin-top: 40px;
  display: inline-block;
  text-align: left;
  width: 85%;
}
.contact-container {
  box-sizing: border-box;
  display: flex;
  padding: 0 40px;
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 700px !important;
  padding-bottom: 80px;
}

.contact-container .left .contact-info {
  color: #42475a;
  font-family: "Prata", sans-serif;
  box-sizing: border-box;
  position: absolute;
  background: white;
  min-height: 100%;
  width: 100%;
  left: 80px;
  top: 80px;
  border: 1px solid #42475a;
  border-radius: 50px;
  text-align: center;
}

.contact-container .left .contact-info p {
  font-family: "Lato", sans-serif;

  display: inline-block;
  text-align: left;
  width: 85%;
  color: #42475a;
}
.contact-container .left .contact-info .form-group {
  padding-top: 20px;
}

.contact-container .left .contact-info .form-group input {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #42475a;
  font-family: "Lato", sans-serif;
  border-radius: 8px;
  padding: 0 12px;
  margin-top: 20px;
  width: 85%;
}
.contact-container .left .contact-info .form-group input:focus {
  box-shadow: none;
}

.contact-container .left .contact-info button {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  width: 200px;
  height: 60px;
  background-color: #42475a;
  color: #ffffff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

@media only screen and (max-width: 992px) {
  .contact-container .right {
    display: none;
  }
  .contact-container .left {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .contact-container .left .contact-info {
    overflow: hidden;
    border: 1px solid #42475a;
    /*border-radius: 50px;*/
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
