.content-dash h1 {
  font-family: 'Prata', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 65.0663px;
  line-height: 88px;
  color: #ffffff;
  margin-top: 50px;
}
.content-dash h2 {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26.496px;
  line-height: 141%;
  color: #ffffff;
}
.content-dash {
  padding-left: 20px;
  padding-right: 300px;
}
@media only screen and (max-width: 768px) {
  .content-dash h1 {
    font-family: 'Prata', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #0b0b0e;
    margin-top: 0px;
  }
  .content-dash h2 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141%;
    color: #42475a;
  }
  .content-dash {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.btns img {
	width: 80px;
	height: 80px;
}