.content h1 {
  font-family: "Prata", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 55.2px;
  line-height: 75px;
  text-align: center;
  color: #ffffff;
  text-shadow: 3px 1px 2px rgba(0, 0, 0, 0.25);
  margin-top: 50%;
}

.content h1 {
  color: #ffffff;
}

@keyframes animation {
  0% {
    text-shadow: 0 0 10px #b2bbe5, 0 0 20px #b2bbe5b3, 0 0 30px #b2bbe55f;
  }
  100% {
    text-shadow: 0 0 5px #b2bbe5, 0 0 10px #b2bbe5b3, 0 0 15px #b2bbe5b3;
  }
}
.content p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141%;
  text-align: center;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .content h1 {
    font-family: "Prata", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20.2px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    text-shadow: 3px 1px 2px rgba(0, 0, 0, 0.25);
    margin-top: 30px;
  }
  .content p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141%;
    text-align: center;
    color: #ffffff;
  }
  .content {
    padding: 30px;
  }
}

.content .home-button {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  width: 200px;
  height: 60px;
  background-color: #42475a;
  color: #ffffff;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block-start: 1em;
  margin-block-end: 1em;
  border-radius: 6px;
  transition: all 500ms;
}
.content .home-button:hover {
  letter-spacing: 1.8px;
}
.landing-hero .content a {
  width: min-content;
  display: inline-flex;
}
