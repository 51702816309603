section.payment {
  padding: 2rem 0 4rem;
}

section.payment h2 {
  text-align: center;
  font-family: var(--prata-font);
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #0b0b0e;
}

.payment .container {
  width: var(--width);
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.payment .container img {
  filter: grayscale(70%);
}
.payment .container img:hover {
  filter: grayscale(0%);
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  .payment .container {
    flex-direction: column;
  }

  .payment .container img {
    margin: 1rem 0;
    filter: grayscale(70%);
  }
  .payment .container img:hover {
    margin: 1rem 0;
    filter: grayscale(0%);
  }

  section.payment h2 {
    text-align: center;
    font-family: var(--prata-font);
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #000000;
  }
}
