.contentab h1 {
  font-family: 'Prata', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 53.07px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 100px;

}



.contentab h1 {
  color: #ffffff;
}

@keyframes animation {
  0% {
    text-shadow: 0 0 10px #b2bbe5, 0 0 20px #b2bbe5b3, 0 0 30px #b2bbe55f;
  }
  100% {
    text-shadow: 0 0 5px #b2bbe5, 0 0 10px #b2bbe5b3, 0 0 15px #b2bbe5b3;
  }
}

.about-c p span {
  font-family: 'Prata', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 55px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.about-c p strong {
  font-family: 'Segoe UI', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 768px) {
  .contentab h1 {
    font-weight: 400;
    font-size: 27.07px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    margin-top : 40%
  }
  .about-c p span {
    font-family: 'Prata', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
  }
  .about-c p strong {
    font-family: 'Segoe UI', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}


.abtbg {
  background-image: url("../assets/aboutBg.png");
  background-color: white;
  background-size: cover;
}