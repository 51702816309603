.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: var(--width);
  margin: auto;
  /*padding-left: 70px;*/
}

.form-title {
  font-family: "Prata", serif;
  margin-top: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #000000;
}

.form-sub-title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141%;
  color: #0b0b0e;
  margin-top: -30px;
}

.hero-form {
  font-family: "Lato", sans-serif;
  padding-top: 1px;
}

.hero-form > div {
  border: 1px solid var(--input-border);
  width: 60%;
  margin: 1rem 0;
  border-radius: 6px;
  overflow: hidden;
}
.hero-form > div input {
  font-size: 14px;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif !important;
  letter-spacing: 1.2px;
}

.hero-form .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  box-sizing: border-box;
}

.hero-form img {
  cursor: pointer;
}

.hero-form div > input,
.hero-form .button {
  width: 100%;
  box-sizing: border-box;
}

.hero-form input:not(input[type="checkbox"]) {
  padding: 8px 12px;
  height: 50px;
}

.hero-form input[type="submit"] {
  display: block;
  width: 60%;
  background-color: var(--primary);
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  text-align: center;
  -webkit-appearance: none;
}

.hero-form label {
  display: block;
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.signup form > div:nth-child(1) {
  border: none;
  padding-right: 0;
}

.signup form > div:nth-child(1) input {
  border: 1px solid #9fa0a5;
  width: 48%;
}

.signin,
.signup,
.verification,
.new_password {
  width: 50%;
  transform: translateY(-2rem);
}
/* verification */
.verification,
.new_password {
  padding-top: 2rem;
}

.verification h1,
.new_password h1 {
  margin-top: 2rem;
}

.verification form input {
  text-align: left;
}

.verification h1 {
  margin-bottom: 0;
}
/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  .hero {
    width: calc(100% - 30px);
    display: block;
    margin-top: 30%;
    padding-left: 0px;
  }

  .form-title {
    font-family: "Prata", serif;
    margin-top: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 24.2027px;
    line-height: 40px;
    color: #000000;
  }

  .form-sub-title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 121%;
    color: #0b0b0e;
    margin-top: -10px;
  }

  .signin,
  .signup {
    width: 100%;
    transform: translateY(0);
  }

  .hero-form input[type="submit"],
  .hero-form div {
    width: 100%;
  }
  .signup-hero {
    margin-top: 10%;
  }
  .verification,
  .new_password {
    width: 100%;
    transform: translateY(0);
  }
}

/* Style inputs with type="text", select elements and textareas */
input[type="text"],
input[type="password"],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border-radius: 6px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: #a6b1e1;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #a6b1e1;
}

@media only screen and (max-width: 992px) {
  input[type="text"],
  input[type="password"],
  select,
  textarea {
    width: 100%; /* Full width */
  }
}

.app-horizontal-devider {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 60%;
  margin-top: 10px;
  color: grey;
}

.app-horizontal-devider::before,
.app-horizontal-devider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #dbdbdb;
  margin: 0 24px;
}

.app-auth-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444;
  width: 60%;
  border-radius: 6px;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 10px;
  height: 51px;
}

.app-auth-link-container-google {
  border: thin solid #888;
  background-color: white;
}

.app-auth-link-container-facebook {
  background-color: #1877f2;
}

.app-auth-link-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.app-auth-link-icon-google {
  background-image: url(../assets/google-logo.png);
  background-position: center;
}

.app-auth-link-icon-facebook {
  background-image: url(../assets/facebook-logo.png);
}

.app-auth-link-text {
  padding-left: 8px;
  font-size: 14px;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif !important;
  letter-spacing: 1.5px;
}

.app-color-white {
  color: white;
}

@media only screen and (max-width: 768px) {
  .app-horizontal-devider {
    width: 100%;
  }

  .app-auth-link-container {
    width: 100%;
  }
}
