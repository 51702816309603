section.testimonials {
  overflow: hidden;
}

section.testimonials,
section.payment {
  background: rgba(66, 71, 90, 0.3);
}

.testimonials .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 0;
}

.testimonials .container .content {
  width: 80%;
  align-self: center;
  justify-self: center;
  overflow-x: hidden;
}

.testimonials .container .content .quote {
  margin-left: 1rem;
  border-radius: 6px;
}

.testimonials .container .content .content-slides {
  display: grid;
  grid-template-columns: repeat(5, 100%);
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.testimonials .container .content .content-slides p {
  margin-top: 1.5rem;
  font-family: var(--lato-font);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 141%;
  color: #000000;
  text-align: start;
}

.testimonials .container .content .content-slides div {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

/* btns */
.testimonials .container .content #testimonial-btns {
  text-align: end;
  margin-top: 3rem;
}

.testimonials .container .content #testimonial-btns img {
  vertical-align: middle;
  pointer-events: none;
  height: 80px;
}

.testimonials .container .content #testimonial-btns button:nth-child(1) {
  margin-right: 1rem;
}

.testimonials .container .img {
  justify-self: flex-end;
  text-align: right;
}

.testimonials .container .img img {
  width: 80%;
}

.testimonials .container .img .testimonials-mobile-img {
  display: none;
}
.right {
  /*border: 3px solid #42475a;*/
  /*border-radius: 50%;*/
  /*padding: 15px;*/
}
/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  .testimonials .container {
    /* grid-template-columns: 1fr;
        grid-template-rows: repeat(2, minmax(100px, 1fr)); */
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    position: relative;
    padding-block: 20px;
  }

  .testimonials .container .img {
    text-align: center;
  }

  .testimonials .container .content {
    grid-area: 2/1/3/2;
  }

  .testimonials .container .content .quote {
    position: absolute;
    top: 4rem;
    width: 40px;
  }

  .testimonials .container .img {
    grid-area: 1/1/2/2;
    width: 30%;
  }

  .testimonials .container .img img:nth-child(1) {
    display: none;
  }

  .testimonials .container .img .testimonials-mobile-img {
    display: block;
    width: 100%;
  }

  .testimonials .container .content {
    width: 90%;
    padding: 0 1rem;
  }

  .testimonials .container .content .content-slides p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141%;
    color: #000000;
    text-align: start;
    font-family: var(--lato-font);
  }
}
