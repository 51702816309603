footer {
  background-color: var(--grey);
  color: #fff;
  padding: 30px 15px !important;
}

footer .container {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  padding: 4rem 0 5rem;
}

footer .container h3 {
  font-family: var(--prata-font);
  font-weight: 400;
  position: relative;
  padding: 10px 0;
}

footer .container h3::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: #fff;
  left: 0;
  bottom: 0;
}

footer .container > div a,
footer .container p {
  font-family: var(--lato-font);
  font-weight: 400;
  color: white;
}

footer .container > div:nth-child(1) {
  max-width: 35%;
}

footer .container > div:nth-child(2),
footer .container > div:nth-child(3) {
  padding-left: 1rem;
}

footer .container > div:nth-child(2) a {
  display: block;
  color: #fff;
  margin: 1rem 0;
}

footer .container > div:nth-child(2) a img {
  vertical-align: middle;
  margin-right: 10px;
  width: 14px;
}

footer .container > div:nth-child(3) a {
  margin: 0 10px;
}

footer .container > div:nth-child(3) a img {
  width: 28px;
}

footer .copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  border-top: 2px solid rgba(255, 255, 255, 0.4);
  padding: 20px 0 10px;
}

footer .copyright span {
  font-family: var(--poppins-font);
  font-weight: 400;
  font-size: 16px;
}
/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  footer {
    padding-top: 2rem;
  }
  footer .container {
    flex-direction: column;
    width: 90%;
    justify-content: center;
    padding: 0;
  }

  footer .container > div {
    margin-bottom: 3rem;
  }

  footer .container > div h3 {
    margin-top: 0;
  }

  footer .container > div:nth-child(1) {
    max-width: 100%;
  }

  footer .container > div:nth-child(1) p {
    line-height: 22px;
  }

  footer .container > div:nth-child(2),
  footer .container > div:nth-child(3) {
    padding-left: 0;
  }

  footer .copyright {
    width: 90%;
    border-top-width: 1px;
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }

  footer .copyright img {
    width: 90px;
  }

  footer .copyright span {
    font-size: 14px;
  }
}
