.v-reset h1 {
  font-family: 'Prata', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #0b0b0e;
}

.v-reset p {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141%;
  color: #0b0b0e;
}
@media only screen and (max-width: 768px) {
  .v-reset h1 {
    font-family: 'Prata', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #0b0b0e;
  }

  .v-reset p {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 141%;
    color: #0b0b0e;
  }
}
