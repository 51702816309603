.c-service h3 {
  font-family: 'Prata', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30.9966px;
  line-height: 42px;
  color: #ffffff;
}

.time-p span {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .c-service h3 {
    font-family: 'Prata', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 31px;
    color: #ffffff;
  }

  .time-p span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
  }
  .time-p span img {
    padding-right: 4px;
  }
  .img {
    padding-left: 10px;
    padding-right: 10px;
  }
}
