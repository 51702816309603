section.app {
  padding-top: 10px;
}

section.app h2 {
  text-align: center;
  font-family: var(--prata-font);
  font-weight: 400;
  font-size: 40px;
}

.app .container {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
}

.app .container .get-app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: end;
  margin-right: 2rem;
}

.app .container .get-app a {
  font-family: var(--lato-font);
  font-weight: 700;
  padding: 1rem 0;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 1rem 0;
  width: 290px;
  text-align: center;
}

.app .container .get-app a img {
  vertical-align: middle;
  margin-left: 10px;
  width: 25px;
}

.app .container > img {
  transform: translateX(-6rem);
}

.app .mobile-title {
  display: none;
}
#downloadr {
  display: none;
}
#download {
  display: block;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  .app h2:nth-child(1) {
    display: none;
  }

  .app .mobile-title {
    display: block;
  }

  .app .container {
    grid-template-columns: 1fr;
  }

  .app .container .get-app {
    justify-self: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .app .container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .app .container > img {
    width: 100%;
    transform: none;
  }

  .respo-app {
    text-align: center;
    padding-top: 59px;
    padding-bottom: 59px;
  }
  .respo-app h1 {
    font-family: "Prata", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #000000;
  }
  .respo-app button {
    background: #ffffff;
    box-shadow: 0px 4.57849px 21.061px rgba(0, 0, 0, 0.25);
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16.4826px;
    line-height: 141%;
    color: #000000;
    width: 315px;
    height: 65.93px;
  }
  .respo-app button img {
    width: 20px;
    height: 22px;
    margin-left: 20px;
  }
  #downloadr {
    display: block;
  }
}
