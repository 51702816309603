section.advantages {
  padding: 2rem 0;
  padding-top: 100px;
  margin: auto;
  max-width: var(--max-width);
  position: relative;
  overflow: hidden;
  margin-bottom: 4rem;
}

section.advantages h2 {
  text-align: center;
  font-family: var(--prata-font);
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #0b0b0e;
}

.advantages .container {
  padding-top: 1rem;
  position: relative;
}

.advantages .content {
  position: relative;
  padding: 0 2rem;
}

.advantages .content > img {
  width: 100%;
  border-radius: 0px 0px 100px 146px;
}

.advantages .advantages-btns {
  position: absolute;
  right: 2rem;
  top: 20%;
  z-index: 20;
}

.advantages .advantages-btns img {
  pointer-events: none;
}

.advantages .advantages-slider {
  width: 100%;
}

.advantages .advantages-slides {
  display: grid;
  grid-template-columns: repeat(5, 100%);
  transform: translateX(-100%);
  transition: transform 0.5s;
}

.advantages .advantages-btns button {
  cursor: pointer;
}

.advantages .container .content h3 {
  font-family: var(--lato-font);
  margin: 0 0 10px;
  position: absolute;
  width: 52%;
  right: 2rem;
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  line-height: 40px;
  color: #101539;
}

.advantages .content > div {
  align-self: flex-end;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  section.advantages {
    padding-bottom: 0;
    padding-top: 50px;
    padding-left: 13px;
    padding-right: 13px;
  }
  section.advantages h2 {
    font-family: var(--prata-font);
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    text-align: center;
    color: #0b0b0e;
  }
  .advantages .container {
    padding-top: 5rem;
  }
  .advantages .content > img {
    border-radius: 0px 0px 25.3272px 36.9777px;
    margin-top: 10px;
  }

  .advantages .advantages-btns {
    top: 0;
  }

  .advantages-btns span:nth-child(1) img {
    width: 30px;
  }

  .advantages-btns span:nth-child(2) img {
    width: 43px;
  }

  .advantages .content {
    top: -2rem;
    padding: 0 1rem;
  }

  .advantages .container .content h3 {
    /* width: 50%; */
    top: -13px;
    right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 101%;
    color: #101539;
  }
}

@media only screen and (max-width: 1037px) {
  .advantages .container .content h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 420px) {
  .advantages .container .content h3 {
    font-size: 14px;
  }
}
