
section.discount {
    margin-top: 1rem;
    text-align: center;
}

.discount .container {
    width: 80%;
    margin: 3rem auto 4rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: var(--max-width);
}

.discount .container .brown,
.discount .container .purple {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    border-radius: 0 9rem 0 9rem;
    height: 300px;
}

.discount .container .purple {
    background-color: #A6B1E1;
}

.discount .container .brown {
    background-color: #C0938E;
    transform: translate(20px, -20px);
}

.discount .container .content {
    align-self: center;
    text-align: left;
    margin-top: 2rem;
}

.discount .container .content p {
    font-family: var(--prata-font);
    font-weight: 400;
    font-size: 40px;
    color: var(--grey);
    margin: 1rem 0;
}


.discount .container .content p span {
    font-weight: 600;
    color: var(--black);
}

.discount .container .content a span {
    font-family: var(--lato-font);
    font-weight: 400;
    color: var(--black);
}

.discount .container .content a img {
    vertical-align: middle;
}
  

 .discount .container .content a #login {
    display: none;
}
 /* Medium devices (landscape tablets) */
 @media only screen and (max-width: 768px) {

    .discount .container .purple,
    .discount .container .brown {
        display: none;
    }

    section.discount .container {
        flex-direction: column;
    }

    section.discount .container>img {
        display: none;
    }

    .discount .container img.banner {
        display: block;
        width: 100%;
    }

    .discount .container .content {
        position: absolute;
        top: 0;
        left: 10px;
    }

    .discount .container .content p {
        font-size: 30px;
    }

    .discount .container .content a span {
        display: none;
    }

    .discount .container .content a #login {
        display: inline;
    }
}

.promoDes {
    display:  block;
    margin:   0 auto;
    width: 60%;  
  }
  .promoMb {
    display: none;
    margin:   0 auto;
    width: 60%;
  }
  
  @media screen and (max-width: 480px) {
   
  .promoDes {
    display:  none;
    margin:   0 auto;
    width: 60%;

  
  }
  .promoMb {
    display: block;
    margin:   0 auto;
    width: 60%;
  }
  
  }