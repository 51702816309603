section.booking {
  max-width: var(--max-width);
  margin: 3rem auto 0;
  padding-bottom: 3rem;
  width: var(--width);
}

section.booking h2,
section.booking p {
  text-align: center;
}

.booking .booking-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 3rem auto;
  max-width: 600px;
  width: 100%;
}

.booking .booking-form > span {
  margin: 14px;
  font-family: var(--lato-font);
  font-weight: 700;
  font-size: 14px;
}

.booking .booking-form > input {
  border: 1px solid #9fa0a5;
}

.booking .booking-form > button,
.booking .booking-form input {
  padding: 1rem;
}

.booking .booking-form input::placeholder {
  color: #888;
  font-family: var(--lato-font);
  font-weight: 400;
}

.booking .booking-form > button {
  background-color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--raleway-font);
  font-weight: 600;
  border-radius: 6px;
}

.booking .booking-form > button,
.booking .booking-form input {
  width: 100%;
}

.booking .booking-form img {
  vertical-align: middle;
  margin-left: 15px;
}

.booking .container {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 1rem;
  padding: 0 1rem;
}

.booking .container .card {
  position: relative;
  cursor: pointer;
}

.booking .container .card img {
  width: 100%;
}

.booking .container .content {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem;
}

.booking .container .content h4 {
  font-family: var(--lato-font);
  margin-bottom: 0px;
  font-weight: 600;
}

.booking .container .content h4 img {
  vertical-align: sub;
  width: 18px;
  transform: translateX(-2px);
}

.booking .container .content h5 {
  font-family: var(--lato-font);
  font-weight: 400;
  margin: 5px 0 0;
}

.booking .more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 5rem;
}

.booking .more .btn,
.booking .more a {
  background-color: var(--primary);
  padding: 1rem 3rem;
}

.booking .more .btn span,
.booking .more a span {
  font-family: var(--raleway-font);
  font-weight: 600;
  color: var(--black);
}

.booking .more .btn img,
.booking .more a img {
  width: 14px;
  vertical-align: baseline;
  margin-left: 5px;
}

.booking h2 {
  font-family: "Prata", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #000000;
}

.booking p {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 141%;
  color: #000000;
}

.booking-form input {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #bdbdbd;
}

.booking-form-button {
  border-radius: 0%;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}

.content h4 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.content h4 img {
  width: 18.68px;
  height: 20.25px;
}
.content h5 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 141%;
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .booking h2 {
    font-family: "Prata", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #000000;
    padding: 0px 30px 0px 30px;
  }

  .booking p {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141%;
    text-align: center;
    color: #000000;
    padding: 0px 30px 0px 30px;
  }

  .booking .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 1rem;
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .booking .container {
    grid-template-columns: 1fr;
  }
}
