.salons {
  max-width: var(--max-width);
  margin: 3rem auto 0;
  padding-bottom: 3rem;
  width: var(--width);
}
.salons .center-btn {
  text-align: center;
}
.salons h2 {
  font-family: var(--prata-font);
  font-style: normal;
  font-weight: 400;
  font-size: 44.2027px;
  line-height: 60px;
  color: #0b0b0e;
  text-align: center;
  margin-bottom: 60px;
}

.salons .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  padding: 0 1rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 1199px) {
  .salons .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.salon-card {
  background-color: #fff;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: left;
  border-radius: 15px;
  overflow: hidden;
}

.salon-card .img {
  position: relative;
  padding-inline: 0 !important;
}

.salon-card .img img:nth-child(1) {
  width: 100%;
  height: 307px;
  object-fit: cover;
}

.salon-card .content-sln {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.salon-card .content-sln > div:nth-child(1) {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  flex-flow: row nowrap;
}

.salon-card .content-sln > div h3 {
  font-family: var(--prata-font);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #0b0b0e;
  margin-bottom: 0;
  margin-top: 0px;
  width: calc(100% - 100px);
  overflow: hidden;
}

.salon-card .content-sln > div span {
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--roboto-font);
  font-weight: 700;
}

.salon-card .content-sln .rating img {
  width: 90px;
}

.salon-card .content-sln .rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.salon-card .content-sln .rating a.btn-s {
  margin-left: auto;
  margin-right: auto;
}

.salon-card .content-sln p {
  /* display: flex;
  align-items: baseline; */
  font-family: var(--lato-font);
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16.7627px;
  line-height: 141%;
  color: #0b0b0e;
}

.salon-card .content-sln p img {
  margin-right: 10px;
  height: 20px;
  transform: translateY(2px);
}

.salon-card a {
  background-color: #0f0f0f;
  width: 90%;
  display: block;
  text-align: center;
  padding: 1rem 0;
  font-family: var(--lato-font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 26px;
  border-radius: 6px;
  letter-spacing: 1.5px;
  transition: all 500ms;
}
.salon-card a:hover {
  letter-spacing: 3px;
}
.salons .search {
  display: flex;
  box-shadow: 0 4px 15px #d1cdcd;
  width: max-content-sln;
  margin: 1rem auto 0;
}

.salons .search > div {
  padding: 5px 2rem;
  position: relative;
}

.salons .search > div div:nth-child(1),
.salons select {
  padding: 10px 0;
}

.salons select {
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.salons select option {
  background-color: #fff;
  color: red;
}

.salons .search > div div:nth-child(1) span {
  font-family: var(--lato-font);
  font-weight: 600;
  color: rgba(154, 157, 165, 1);
  font-size: 14px;
  pointer-events: none;
}

.salons .search > div > div img {
  vertical-align: middle;
  pointer-events: none;
}
.salons .search > div > :not(div:last-child) img {
  margin-left: 10px;
}
.salons .search > div > div:last-child img {
  margin-right: 10px;
}

.salons .search > div div:nth-child(2) {
  background-color: var(--primary);
  position: absolute;
  left: 0;
  top: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
  width: 100%;
  padding: 0 10px;
  z-index: 20;
  box-sizing: border-box;
}

.salons .search > div > div:nth-child(2) span {
  display: block;
  padding: 5px 0;
  cursor: pointer;
  color: #fff;
  font-family: var(--lato-font);
}

.salons .search > div:hover div:nth-child(2) {
  max-height: 200px;
}

.salons .search > div:last-child {
  background-color: var(--primary);
  cursor: pointer;
}

.salons .search > div:last-child div span {
  color: var(--black);
}

section.salons .more {
  background-color: var(--primary);
  padding: 1rem 2rem;
  display: inline-block;
  color: var(--black);
}

section.salons .more span {
  font-family: var(--lato-font);
  font-weight: 600;
}

section.salons .more img {
  margin-left: 10px;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 768px) {
  .salons .search {
    flex-direction: column;
    width: 90%;
    text-align: center;
  }

  .salons .search > div {
    padding: 10px 2rem;
  }

  .salons .search > div div:nth-child(2) {
    background-color: #222;
  }
  .salons h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 33.1603px;
    line-height: 45px;
    color: #0b0b0e;
    padding: 0px 20px 0px 20px;
    margin-bottom: 60px;
  }
  .salons .search > div div.display-drop {
    max-height: 200px;
  }

  .salons {
    overflow-x: hidden;
  }

  .salons .container {
    padding-bottom: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  .salons .container::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

#best .more-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  width: 200px;
  height: 60px;
  background-color: #42475a;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-block-start: 1em;
  margin-block-end: 1em;
  border-radius: 6px;
  transition: all 500ms;
}
#best .more-button button:hover {
  letter-spacing: 1.3px;
}
#best .more-button a {
  display: inline-flex;
}
#best .more-button {
  text-align: center;
}
