.help-floater {
  position: fixed;
  bottom: 35vh;
  right: 40px;
  z-index: 200;
  overflow: hidden;
  user-select: none;
}
.help-floater > .toogle {
  user-select: none;

  width: 80px;
  height: 80px;
  background: #42475a;
  color: #a6b1e1;
  font-size: 45px;
  font-family: "Lato", sans-serif;
  fill: #a6b1e1;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/*.help-floater .drop-help {
    height: 0;
}*/
.help-floater .drop-help div {
  user-select: none;

  width: 80px;
  height: 80px;
  background: #42475a;
  color: #a6b1e1;
  font-size: 45px;
  font-family: "Lato", sans-serif;
  fill: #a6b1e1;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.help-floater .drop-help div a {
  color: #a6b1e1;
  text-decoration: none;
}
.help-floater .drop-help div a img {
  max-height: 35px;
  width: 35px;
}
.help-floater .drop-help div.wts img {
  max-height: 50px;
  width: 50px;
}
.hide-help.drop-help {
  height: 0;
  display: none;
}
.show-help.drop-help {
  height: fit-content;
}
